/* eslint-disable @typescript-eslint/member-ordering */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getSatApi(path: string, params: any) {
    return this.http.get(path).toPromise();
  }

  getApi(path: string, params: any) {
    return this.http.get(this.apiUrl + path).toPromise();
  }

  postApi(path: string, body: any) {
    return this.http.post(this.apiUrl + path, body).toPromise();
  }

  deleteApi(path: string, params: any) {
    return this.http.delete(this.apiUrl + path).toPromise();
  }

  putApi(path: string, body: any) {
    return this.http.put(this.apiUrl + path, body).toPromise();
  }

  postApiSat(path: string, body: any) {
    let headers = new Headers();
    headers.append('accept', 'text/plain');
    return this.http
      .post(
        'https://rockblock.rock7.com/rockblock/MT?imei=300434068638400&username=nationaltelecomtot%40gmail.com&password=123456789%40NT&data=e1',
        { headers: headers }
      )
      .subscribe(
        (res) => {
          console.log('res', res);
        },
        (err) => {
          console.log('error', err.message);
        }
      );

    // console.log('postApiSat',path)
    // const myHeader = {
    //   'Content-Type': 'text/plain'
    // };
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // return this.http.post(path,{ headers, responseType: 'text'});

    // return this.http.post(path, body,{headers:myHeader}).toPromise();

    //   const myHeader = {
    //     'accept': 'text/plain'
    //   };
    //   return this.http
    //     .post<any>(path, body, { headers: myHeader })
    //     .pipe(catchError(this.handleError));
  }
}
