import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root',
})
export class MissionShowService {

  constructor(private apiService: ApiService) {}
  postMissionCmd(
    droneName: string,
    missionVariable: string,
    missionValue: number,
    mission_id:number
  ) {
    let body = {
      drone_name: droneName,
      mission_id:mission_id,
      mission_variable: missionVariable,
      mission_value: missionValue,
    };
    return this.apiService.postApi('/mission_cmd.php', body);
  }
  postMissionStausCheck(droneName: string) {
    let body = {
      drone_name: droneName,
    };
    return this.apiService.postApi('/mission_status_check.php', body);
  }
  getMissionStatusCheck(droneName:string,mission_id:string) :Promise<any>{
    return new Promise((reslove,reject)=>{
      try{
        let body = {
          drone_name: droneName,
          mission_id:mission_id
        };
        this.apiService.postApi('/mission_status_check.php', body).then(async (result:any)=>{
          reslove(result)
        })
      }catch(error){
        reject(error)
      }
    })
  }
  async postSelectMission(mission_id:string,drone_name:string,mission_key:string,username:string):Promise<any>{
    return new Promise ((resolve,reject)=>{
      
      try{
        this.apiService.postApi('/drone_select_mission.php',{
          "mission_id": mission_id,
          "drone_name": drone_name,
          "mission_key":mission_key,
          "username":username

      }).then(async (result:any)=>{
          resolve(result)
      })
      }catch(error){
        reject(error)
      }
    })
  }
  postMissionLoad(mission_id: string,droneName:string) {
    let body = {
      mission_id: mission_id,
      drone_name: droneName,
    };
    return this.apiService.postApi('/mission_load.php', body);
  }
  postMissionList() {
    //console.log("postMissionList=",body);
    return this.apiService.postApi('/mision_ini_list.php', {
      "key_select": "drone_name",
      "key_data": ""
  });
  }
  postMissionListAll() {
    //console.log("postMissionList=",body);
    return this.apiService.postApi('/mission_list.php', {
      "key_select": "mission_name",
      "key_data": ""
  });
  }
  postLoadImageVdo(mession_id) {
    let body = {
      mission_id: mession_id,
    };
    return this.apiService.postApi('/mission_load_img.php', body);
  }
  mission_setup(value){
    return this.apiService.postApi('/mission_setup.php', value);
  }
}
