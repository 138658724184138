import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private apiService: ApiService) {}
  getHistoryDetail(name: string) {
    let body = {
      drone_name: name,
    };
    return this.apiService.postApi('/mission_log.php', body);
  }
  getHistory(mission_id: string) {
    let body = {
      mission_id: mission_id,
    };
    return this.apiService.postApi('/mission_history.php', body);
  }
}
