/* eslint-disable @typescript-eslint/naming-convention */

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import menu from '../assets/menu/menu.json';
import { Preferences } from '@capacitor/preferences';
import { LanguageService } from 'src/app/service/language.service';
import { ApiService } from './service/api.service';
import { interval } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  fullname: any = '';
  role: any = '';
  username: any = '';
  userId: any = '';
  isLogin: any;

  public listMenu = [
    {
      translate: '',
      collapsible: false,
      icon: '',
      url: 't',
      role: ['admin', 'superadmin', 'user'],
    },
  ];
  public tmpListMenu: any = menu;
  isDataActivated = 0;
  constructor(
    public translate: TranslateService,
    private navCtrl: NavController,
    private apiService: ApiService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  isActivated(value) {
    this.isDataActivated = value;
  }

  async ngOnInit() {
    const lang = await Preferences.get({ key: 'lang' });
    this.languageService.setLanguage(lang.value);
    this.translate.use(lang.value);
    this.userId = await Preferences.get({ key: 'userId' });
    this.fullname = await Preferences.get({ key: 'fullname' });
    this.role = await Preferences.get({ key: 'privilage' });
    this.username = await Preferences.get({ key: 'username' });
    this.isLogin = await Preferences.get({ key: 'isLogin' });
    this.checkMenuRole(this.role.value);
    this.checkIslogin();
    const numbers = interval(3000);
    const intervalLoopFunction = numbers.pipe();
    intervalLoopFunction.subscribe(() => {
      this.checkIslogin();
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const activeIndex = menu.findIndex((item) => item.url === event.url);
        if (activeIndex >= 0) {
          this.isActivated(activeIndex);
        }
      });
  }

  async logOut() {
    this.apiService
      .postApi('/sign_out.php', {
        id: this.userId.value,
      })
      .then(async (result: any) => {
        console.log('logout', result);
        await Preferences.set({
          key: 'isLogin',
          value: 'false'
        });
        this.navCtrl.navigateRoot('/login-admin');
      });
  }

  async checkIslogin() {
    this.apiService
      .postApi('/userList.php', {
        key_select: 'id',
        key_data: this.userId.value,
      })
      .then(async (result: any) => {
        console.log('checkIslogin', result.data_list[0]);
        if (result.data_list[0].login_status != '0') {
          this.navCtrl.navigateRoot('/login-admin');
        }
      });
    // this.isLogin = await Preferences.get({ key: 'isLogin' });
    // if(this.isLogin.value != 'true'){
    //   console.log('isLogin', this.isLogin.value);
    //   this.navCtrl.navigateRoot('/login-admin');
    // }
    console.log('isLogin', this.isLogin.value);

  }

  async checkMenuRole(role) {
    this.listMenu = [];
    for await (const item of this.tmpListMenu) {
      const menuArr = item.role;
      if (menuArr.indexOf(role) > -1) {
        this.listMenu.push(item);
      }
    }
  }
}
