import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguageSubject: BehaviorSubject<string>;

  constructor(private translateService: TranslateService) {
    this.currentLanguageSubject = new BehaviorSubject<string>(this.translateService.currentLang);
  }

  getCurrentLanguage() {
    return this.currentLanguageSubject.asObservable();
  }

  setLanguage(language: string) {
    this.translateService.use(language);
    this.currentLanguageSubject.next(language);
  }
}
