import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login-admin',
    pathMatch: 'full',
  },
  {
    path: 'login-admin',
    loadChildren: () =>
      import('./login-admin/login-admin.module').then(
        (m) => m.LoginAdminPageModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'user-event-log',
    loadChildren: () =>
      import('./event-log/event-log.module').then((m) => m.EventLogPageModule),
  },

  {
    path: 'mission-initial',
    loadChildren: () =>
      import('./mission-initial/mission-initial.module').then(
        (m) => m.MissionInitialPageModule
      ),
  },
  {
    path: 'mission-initial-edit',
    loadChildren: () =>
      import('./mission-initial-edit/mission-initial-edit.module').then(
        (m) => m.MissionInitialEditPageModule
      ),
  },
  {
    path: 'mission-show',
    loadChildren: () =>
      import('./mission-show/mission-show.module').then(
        (m) => m.MissionShowPageModule
      ),
  },
  {
    path: 'drone-management',
    loadChildren: () =>
      import('./drone-management/drone-management.module').then(
        (m) => m.DroneManagementPageModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'node-sensor',
    loadChildren: () =>
      import('./node-sensor/node-sensor.module').then(
        (m) => m.NodeSensorPageModule
      ),
  },
  {
    path: 'node-management',
    loadChildren: () =>
      import('./node-management/node-management.module').then(
        (m) => m.NodeManagementPageModule
      ),
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./report/report.module').then((m) => m.ReportPageModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./user/user.module').then((m) => m.UserPageModule),
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
